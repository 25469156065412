@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@font-face {
  font-family: 'MARTIN Regular';
  src: url('./assets/MARTIN-Regular.woff') format('woff');
  font-display: swap;
}

* {
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}


:root {
  --step---0: clamp(12px, 2vw, 12px);
  --step--0: clamp(12px, 2vw, 16px);
  --clues: clamp(12px, 2vw, 14px);

  --step-0: clamp(12px, 2vw, 17px);
  --step-0-5: clamp(10px, 2vw, 12px);
  --footer-contact: clamp(12px, 2vw, 15px);
  --disclaimer: clamp(13px, 2vw, 20px);
  --step-1: clamp(13px, 2vw, 18px);
  --step-1-5: clamp(13px, 2vw, 20px);
  --step-2-1: clamp(13px, 2vw, 26px);
  --step-2: clamp(19px, 2vw, 26px);
  --step-2-5: clamp(9px, 2vw, 28px);
  --step-2-5-0: clamp(13px, 2vw, 28px);
  --step-2-5-1: clamp(10px, 6vw, 28px);
  --step-3: clamp(18px, 5vw, 36px);
  --step-3-5: clamp(20px, 5vw, 26px);
  --step-3-5-1: clamp(21px, 2vw, 46px);
  --step-4: clamp(30px, 5vw, 90px);
  --step-5:  clamp(25px, 14.1vw, 220px);

  --margin-l-landing: 17vh 0;
  --margin-l: 10vh 0 17vh;

  --letter-spacing-s: 1px;
  --letter-spacing-m: 4px;
  --letter-spacing-l: 6px;
  --tracking-s: -0.075ch;

  --mt: 3vh 0 0;

  --line-height-s: 0.7;
  --line-height-m: 0.8;
  --line-height-l: 1.2;
  --line-height-xl: 2;



  --white: #ffffff;
  --off_white: #dcd3d3;
  --blog_white: #e9e9e9;
  --footer_white: #faf4f4;
  --dusty_pink: #f8bebe;
  --dusty_black: #303030;
  --menu_black: #3e3e3e;
  --menu_pink: #e0b5b5;
  --menu_white: #f7f6f2;
  --black: #181818;
  --blog_black: #4b4b4b;

}


body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dusty_black);
  position: relative;
}

html {
  scroll-behavior: smooth;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

img,
picture {
  max-width: 100%;
  display: block;
}

p {
  margin: 0;
}

h1 {
  text-transform: uppercase;
  color: var(--dusty_pink);
  margin: 0;
  font-family: 'MARTIN Regular';
  font-size: clamp(35px, 25vw, 700px);
  text-align: end;
  font-weight: 100;
}

.small__title,
.small__h1-title {
  font-size: var(--step-2);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-s);
  margin-bottom: 2vh;
  font-family: 'MARTIN Regular';
  text-align: start;
}

:any-link {
  color: var(--footer_white);
  text-decoration: underline;
  text-decoration-color: var(--dusty_pink);
  display: inline-block;
}

article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.inner {
  /* max-width: 1920px; */
  /* border: 1px solid red; */
  max-width: clamp(200px, 90vw, 1300px);
  width: 85%;
}