.disclaimer__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 60%);
  z-index: 2;
  color: var(--white);
}

.disclaimer__content {
  position: absolute;
  top: 30vh;
  background-color: var(--black);
  font-family: 'Inter', sans-serif;
  width: 80%;
  height: 40vh;
  grid-template-rows: 1fr 1fr 1fr;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: var(--disclaimer);
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.disclaimer__button-wrapper {
  grid-area: 1 / 1 / span 1 / span 2;
  justify-self: end;
}

.disclaimer__button {
  background-color: transparent;
  border: 0;
  color: var(--white);
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0.75rem;
}

.disclaimer__text {
  grid-area: 2 / 1 / span 1 / span 2;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disclaimer__text div {
  line-height: 1.5;
}

.disclaimer__arrow {
  fill: var(--white);
  width: 2vw;
  margin-right: 1vw;
}

.disclaimer__link {
  grid-area: 3 / 1 / span 1 / span 1;
  font-size: var(--footer-contact);
  display: flex;
}

.grumpy__dino-disclaimer {
  position: absolute;
  right: 0%;
  bottom: 30%;
  grid-area: 3 / 2 / span 1 / span 2;
}

.grumpy__dino-disclaimer img {
  height: clamp(150px, 20vw, 200px);
  width: auto;
}

.popup__content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__wrapper {
  width: 90%;
  display: flex;
  max-width: 800px;
  margin: auto; 
  position: relative;
}

.popup__close {
  position: absolute;
  z-index: 3;
  right: 10px;
  background-color: transparent;
  border: 0;
  color: var(--white);
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0.75rem;
  display: block;
}

.popup__video {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 600px;
  border: 0;
}


@media (min-width: 600px) {
  .disclaimer__content {
    width: 60%;

  }
}

@media (min-width: 900px) {
  .disclaimer__content {
    height: 43vh;
  }
}

@media (min-width: 1200px) {
  .disclaimer__content {
    width: 40%;
  }
}