.blog__wrapper {
  font-family: "MARTIN Regular";
  text-transform: uppercase;
  line-height: var(--line-height-m);
  margin-top: 0;
}

.a {
  margin: var(--margin-l);
}

.back__blog-wrapper {
  width: 100%;
}

.back__blog {
  font-family: "MARTIN Regular";
  text-transform: uppercase;
  line-height: var(--line-height-m);
  font-size: var(--step-3-5);
  color: var(--dusty_pink);
  cursor: pointer;
  text-decoration: none;
  margin-top: 3rem;
  position: relative;
  display: inline;
}

.back__blog::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: var(--off_white);
  transition: width 0.2s ease-in;
}

.back__blog:hover::after {
  width: 100%;
}

.end {
  color: var(--off_white);
  margin: 0 0 10vh;
}

.end::after {
  background-color: var(--dusty_pink);
}

.blog__link {
  text-decoration: none;
  width: 80%;
}

.blog__wrapper-copy,
.blog__wrapper-copy-post,
.work__wrapper-copy-post {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.blog__wrapper-copy-date {
  font-size: var(--step-3-5);
  color: var(--off_white);
  margin: 3rem;
  font-family: "MARTIN Regular";
  text-transform: uppercase;
  line-height: var(--line-height-m);
}

.blog__wrapper-copy-post,
.work__wrapper__title-post {
  width: 80%;
  max-width: 1920px;
  margin: auto;
  align-items: center;
}

.blog__wrapper-title,
.work__wrapper__title-post {
  color: var(--dusty_pink);
  font-size: clamp(25px, 45vw, 700px);
  text-align: center;
}

.blog__wrapper__title-post,
.work__wrapper__title-post {
  /* font-size: clamp(25px, 16vw, 207px); */
  font-size: clamp(25px, 9vw, 140px);
  white-space: normal;
  font-family: "MARTIN Regular";
  text-transform: uppercase;
  line-height: var(--line-height-m);
  text-align: center;
}

.work__wrapper__title-post {
  font-size: clamp(25px, 9vw, 140px);
  margin: 3rem;
}

.blog__copy-line {
  background: var(--dusty_pink);
  height: clamp(3px, 1vw, 6px);
  width: clamp(20px, 23vw, 300px);
  display: inline-block;
  margin: 5rem 0 0;
}

.blog__copy__small-line {
  background: var(--dusty_pink);
  height: 1.5px;
  width: clamp(45px, 11vw, 60px);
  display: inline-block;
  margin: 1rem 0;
}

.blog__wrapper-subtitle {
  color: var(--off_white);
  font-size: clamp(22px, 9.1vw, 140px);
  text-align: center;
}

.blog__thumb-posts {
  max-width: 1320px;
  margin: auto;
  margin-bottom: 20vh;
}

.blog__content-grid {
  display: grid;
  grid-template-columns: 1fr;
  font-family: "MARTIN Regular";
  padding: 0;
  row-gap: 1vh;
  column-gap: 1vw;
}

.picked_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  row-gap: 1vh;
  width: 100%;
}

.blog__content-list {
  background-color: var(--blog_white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: clamp(20px, 97vw, 700px);
  max-width: clamp(20px, 80vw, 700px);
  transition: 0.5s ease-in-out;
}

.blog__content-list:hover {
  background-color: var(--dusty_pink);
}

.blog__content-list:hover .blog__content-date {
  color: var(--blog_black);
}

.blog__content-list:hover .blog__content-title {
  color: #fff;
}

.blog__content-list:hover .blog__content-img,
.blog__content-list:hover .blog__content-video {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.blog__content-img {
  object-fit: cover;
  width: 100%;
  height: clamp(20px, 48.5vw, 700px);
  margin-top: 2rem;
  transition: 0.5s ease;
}

.blog__content-video {
  margin-top: 2rem;
  transition: 0.5s ease;
}

.blog__content-date {
  color: var(--dusty_pink);
  font-size: var(--step-2-5-1);
  transition: 0.5s ease;
}

.blog__content-title {
  color: var(--blog_black);
  font-size: var(--step-3-5);
  margin-bottom: 4vh;
  text-transform: uppercase;
}

.tags {
  font-family: "Inter", sans-serif;
  font-size: var(--step---0);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: flex-start;
  margin-bottom: 2vh;
}

.tag {
  border: 1px solid var(--blog_black);
  padding: 0.2rem 0.3rem;
  border-radius: 100vmax;
  background-color: transparent;
  color: var(--blog_black);
  margin-right: 0.2rem;
}

.tags .tag:last-child {
  margin-right: 0;
}

.blog__copy-text,
.blog__copy-text-quote {
  font-family: "Inter", sans-serif;
  color: var(--footer_white);
  text-transform: none;
  margin: 7vh auto 0;
  width: 90%;
  font-size: var(--step-1);
  line-height: 1.5;
}

.blog__copy-text span {
  font-weight: 600;
}

.blog__copy-wrapper .blog__copy-text:last-child {
  margin-bottom: 10vh;
}

.the__end {
  max-width: 70%;
  margin: 5rem auto 0;
  font-size: var(--step-3-5);
  color: var(--dusty_pink);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "MARTIN Regular";
  text-transform: uppercase;
  line-height: var(--line-height-m);
}

.blog__copy-asset {
  margin: 7vh auto 0;
  width: 100%;
}

.blog__copy-asset img {
  margin: auto;
}

.blog__copy-text-quote {
  margin: 0.5rem auto 0;
}

.blog__copy-text-quote p {
  font-style: italic;
}

code {
  font-size: var(--step-1);
  color: var(--dusty_pink);
  background-color: var(--blog_black);
}

.blog__iframe {
  margin: 7vh auto 0;
  width: 100%;
}

.codepen__iframe {
  width: 100%;
  display: block;
  height: 30vh;
}

.blog__ol {
  padding-left: 2rem;
}

.blog__list {
  list-style: upper-roman;
}

.blog__dino {
  height: clamp(75px, 20vw, 300px);
}

@media (min-width: 560px) {
  .blog__content-grid {
    grid-template-columns: 1fr 1fr;
  }

  .blog__content-list {
    min-height: clamp(20px, 48vw, 700px);
    max-width: clamp(20px, 39vw, 700px);
  }

  .blog__content-img {
    max-height: clamp(20px, 23.5vw, 450px);
  }
}

@media (min-width: 900px) {
  .blog__copy-text,
  .blog__copy-text-quote {
    width: 80%;
  }

  .blog__content-grid,
  .picked_grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .blog__content-list-test div {
    margin: 0 2rem;
  }

  .blog__content-list {
    min-height: clamp(20px, 33.5vw, 450px);
    max-width: clamp(20px, 28vw, 450px);
  }

  .blog__content-img {
    max-height: clamp(20px, 16.5vw, 450px);
    width: clamp(20px, 23vw, 450px);
  }

  .blog__content-title {
    font-size: var(--step-3-5-1);
  }

  .tags {
    margin-bottom: 2vh;
  }
}

@media (min-width: 1100px) {
  .blog__copy-wrapper {
    margin: auto;
    max-width: 80%;
  }
}
