.carousel__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.carousel__wrapper--default {
  position: absolute;
}

.carousel__wrapper--blog {
  position: relative;
  z-index: 1;
}

.carousel__content {
  display: grid;
  grid-template-columns: 0.4fr 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
  grid-template-rows: 1fr 0.2fr;
  width: clamp(200px, 90vw, 1300px);
}

.right__arrow {
  grid-area: 1 / 1 / span 1 / span 1;
  align-self: center;
  margin: 1vw 1vw 0 0;
  width: 3vw;
}

.carousel__content-titles {
  align-self: center;
  grid-area: 1 / 2 / span 1 / span 5;
  position: relative;
  padding: 0;
  margin: 0;
  font-size: var(--step-4);
  text-transform: uppercase;
  font-family: "MARTIN Regular";
  z-index: 4;
}

.none {
  display: none;
}

.t-image {
  grid-area: 1 / 3 / span 1 / span 4;
}

.carousel__content-images {
  height: clamp(150px, 27vw, 900px);
  width: clamp(150px, 27vw, 900px);
  position: relative;
  margin: 0 auto;
  margin-bottom: 2vh;
  padding: 0;
}

.carousel__content-li {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.carousel__content-li.active {
  position: absolute;
  z-index: 1;
}

.carousel__content-li video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carousel__content-li img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wp__button-right,
.wp__button-left {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 1vh;
  opacity: 0.5;
  fill: var(--white);
}

.wp__button-left .arrow__wp,
.wp__button-right .arrow__wp {
  transition: 0.3s ease;
}

.wp__button-left:hover,
.wp__button-right:hover {
  opacity: 1;
}

.wp__button-left:hover .arrow__wp {
  transform: scaleX(2.5);
  transform-origin: center start;
  transition: all 0.3s ease;
  fill: var(--dusty_pink);
}

.wp__button-right:hover .arrow__wp {
  transform: scaleX(2.5);
  transform-origin: center end;
  transition: all 0.3s ease;
  fill: var(--dusty_pink);
}

.wp__button-right {
  grid-area: 2 / 5 / span 1 / span 1;
  align-self: baseline;
  justify-self: end;
  width: clamp(34px, 5vw, 300px);
}

.arrow__wp {
  margin: 0;
}

.wp__button-left {
  grid-area: 2 / 4 / span 1 / span 1;
  align-self: baseline;
  justify-self: start;
  width: clamp(34px, 5vw, 300px);
}

@media (min-width: 760px) {
  .carousel__content {
    grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
  }

  .wp__button-right,
  .wp__button-left {
    margin-top: 2vh;
  }
}

@media (min-width: 1050px) {
  .wp__button-right,
  .wp__button-left {
    margin-top: 4vh;
  }
}

@media (min-width: 1550px) {
  .carousel__content {
    grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
  }
}
