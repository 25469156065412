.about__thumb {
  margin: var(--margin-l-landing)
}

.w {
  color: var(--off_white);
}

.bold__quote {
  color: var(--dusty_pink);
  text-transform: uppercase;
  line-height: var(--line-height-s);
  font-size: var(--step-5);
  font-family: 'MARTIN Regular';
}



.terse {
  display: flex;
  color: var(--white);
  margin: var(--mt);
  font-family: 'Inter', sans-serif;
  justify-content: flex-start;
  align-items: center;
}

.right__arrow-about {
  width: 3vw;
}

.about__more {
  margin-left: 2vw;
  font-size: var(--step-0);
}