.trivia__wrapper {
  margin: var(--margin-l-landing)
}

.p {
  color: var(--dusty_pink);
}

.inner__trivia {
  width: 85%;
  max-width: clamp(200px, 90vw, 1300px);
}

.card__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr;
  row-gap: 1rem;
  column-gap: 0.3rem;
}


.card {
  grid-area: 1 / 1 / span 1 / span 2;
  background-color: #f0f0f0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.7fr 0.3fr;
  padding: 1rem 1rem 0;
  transition: 0.5s ease-in-out;
  height: clamp(20px, 24vh, 700px);
  position: relative;
}

.card__img,
.card__img2 {
  grid-area: 2 / 1 / span 1 / span 1;
  height: clamp(20px, 24vh, 700px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(240, 240, 240, 0.98);
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f4f4D4' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  position: relative;
  padding: 1rem 1rem 3rem 1rem;
  transition: 0.5s ease-in-out;
}

.card__img2 {
  grid-area: 2 / 2 / span 1 / span 1;
}


.trivia__tape {
  position: absolute;
  top: -5%;
  width: clamp(88px, 14vw, 200px);
}

.card__info {
  width: 100%;
  grid-area: 1 / 1 / span 1 / span 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.card__img img,
.card__img2 img {
  object-fit: cover;
  height: clamp(20px, 24vh, 700px);
  width: 100%;
}

.card__img div,
.card__img2 div {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.trivia__p {
  font-size: var(--step-2-5-0);
  margin-bottom: 2vh;
  margin-top: 0.3vh;
  font-family: 'Inter', sans-serif;
  color: var(--blog_black);
}

.trivia__button {
  align-self: end;
  justify-self: center;
  grid-area: 2 / 1 / span 2 / span 1;
  margin-bottom: 1vh;
  z-index: 1;
}

.round {
  border-radius: 100vmax;
  height: clamp(2.7rem, 4vw, 4rem);
  width:  clamp(2.7rem, 4vw, 4rem);
  cursor: pointer;
  background-color: var(--dusty_black);
  border: 1px solid var(--dusty_black);
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trivia__svg {
  height: 50%;
  width: 50%;
  fill: var(--dusty_pink);
}


@media (min-width: 480px) {

  .card__img,
  .card__img2 {
    min-height: clamp(20px, 40vw, 700px);
  }
}

@media (min-width: 760px) {

  .card__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5vw;
  }

  .card {
    min-height: clamp(20px, 35vw, 700px);
    max-width: clamp(500px, 21vw, 700px);
    grid-area: 1 / 1 / span 1 / span 1;
  }

  .card__img,
  .card__img2 {
    min-height: clamp(20px, 35vw, 700px);
    max-width: clamp(500px, 21vw, 700px);
    grid-area: 1 / 1 / span 1 / span 1;
  }



  .card__img {
    grid-area: 1 / 2 / span 1/ span 1;
    padding: 1.5rem 1.5rem 4.5rem 1.5rem;
  }


  .card__img2 {
    grid-area: 1 / 3 / span 1/ span 1;
    padding: 1.5rem 1.5rem 4.5rem 1.5rem;

  }

  .card__img img,
  .card__img2 img {
    max-height: clamp(4rem, 23vw, 26rem);
  }

}

@media (min-width: 850px) {
  .card__img img,
  .card__img2 img {
    min-height: clamp(4rem, 26vw, 26rem);
    max-height: clamp(4rem, 26vw, 26rem);
  }
}


@media (min-width: 1600px) {
  .inner__trivia {
    max-width: 1300px;
  }

  .card {
   padding: 2.5rem 1rem;
  }
}
