nav {
  position: relative;
  z-index: 3;
}

.header__wrapper {
  position: absolute;
  width: 100%;
}

.header__content-name {
  text-decoration: none;
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2vh 5vw;
  color: var(--white);
  font-size: var(--step-0-5);
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
}


.hamburger {
  cursor: pointer;
  display: block;
  z-index: 4;
  background-color: transparent;
  border: none;
}

.hamburger span {
  height: 3px;
  width: 20px;
  margin: 3px;
  display: block;
  background: var(--white);
}

.menu {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  position: fixed;
  background-color: var(--menu_pink);
  height: 100vh;
  width: 100%;
}

.menu__grid {
  max-width: 1300px;
}

.menu__ul {
  display: flex;
  justify-content: space-between;
  font-family: 'MARTIN Regular';
  text-transform: uppercase;
  font-size: clamp(15px, 8.15vw, 140px);
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.menu__li {
  line-height: 40%;
}


.d {
  color: var(--menu_black);
  line-height: 85%;
  text-decoration: none;
}

.d:hover {
  color: var(--menu_white);
}

.menu__bold {
  color: var(--menu_white);
  opacity: 0.25;
  font-size: clamp(20px, 21.15vw, 300px);
  text-transform: uppercase;
  line-height: 85%;
  overflow: hidden;
  font-family: 'MARTIN Regular';
}

.lower {
  line-height: 73%;
  overflow: hidden;
}


.menu__fd {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  position: relative;
}

.menu__contact {
  max-width: 100%;
  font-size: var(--step--0);
  height: clamp(46px, 10vw, 100px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  margin-right: 1rem;
  margin-top: 1rem;
}

.menu__link {
  text-decoration: none;
  font-family: 'Inter';
  position: relative;
  display: inline-block;
}

.menu__link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  transition: width 0.2s ease-in;
  background-color: var(--menu_black);
}

.menu__link:hover::after {
  width: 100%;
}

.menu__link:hover {
  color: var(--menu_black);
  ;
}

.small__titlemw {
  color: var(--menu_white);
  font-size: var(--step-2);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-s);
  font-family: 'MARTIN Regular';
}

.menu__contact span {
  background: var(--white);
  height: clamp(1.5px, 1vw, 1.5px);
  width: clamp(10px, 3vw, 100px);
  display: inline-block;
  opacity: 0.7;
}

.mf {
  opacity: 0.7;
}

.grumpy__dino {
  align-self: center;
  justify-self: end;
  position: absolute;
  right: -5%;
  opacity: 0;
}

.grumpy__dino img {
  height: clamp(75px, 20vw, 300px);
}

.menu__svg {
  position: absolute;
  right: 60%;
  bottom: 91%;
}

@media screen and (min-width: 768px) and (orientation: landscape) {
  .grumpy__dino {
    bottom: -11%;
  }

  .menu__fd {
    margin-bottom: 5vh
  }
}


@media (min-width: 768px) {

  .menu__contact {
    max-width: 78%;
    justify-content: space-between;
  }

  .grumpy__dino {
    right: 14.2%;
    bottom: -11%;
  }
}

@media (min-width: 1068px) {

  .menu__fd {
    display: grid;
    grid-template-columns: 0.9fr 0.5fr;
    position: relative;
  }
}