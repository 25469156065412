.wp__wrapper {
  margin-top: 15vh;
}

.small__title {
  color: var(--dusty_pink);
}

.wp__inner-work,
.wp__inner-play {
  color: var(--black);
  font-size: clamp(90px, 30.4vw, 460px);
  text-transform: uppercase;
  letter-spacing: var(--tracking-s);
  line-height: var(--line-height-m);
  font-family: 'Inter', sans-serif;
  text-align: center;
}

.wp__inner-play {
  text-align: center;
}

.deck__wrapper {
  display: relative;
  height: 10vh;
}

.square {
  opacity: 0.5;
  border-radius: 100vmax;
  cursor: pointer;
  font-size: var(--step-0);
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  color: var(--white);
  border: 1px solid var(--white);
  padding: 0.75em 2.5em;
  text-transform: uppercase;
  margin: var(--mt);
  text-decoration: none;
}


.square:hover {
  opacity: 1;
}