.about__copy {
  color: var(--white);
  margin-bottom: 2vh;
  font-size: var(--step-2-1);
  font-family: "inter", sans-serif;
}

.inline__content {
  display: flex;
  gap: 5px;
}

.about__button {
  color: var(--white);
  background-color: transparent;
  border: none;
  font-size: var(--step-2-1);
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  text-decoration-color: var(--dusty_pink);
}

.b {
  margin: 10vh 0 10vh;
}


.mt {
  margin-top: 7vh;
}

.about__stacks {
  background-color: var(--menu_pink);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5vw;
  margin: auto;
  max-width: 2000px;
}

.about__stacks-title {
  grid-area: 1 / 1 / span 1 / span 2;
  justify-self: center;
  color: var(--white);
  font-size: clamp(20px, 35vw, 500px);
  text-transform: uppercase;
  line-height: var(--line-height-m);
  font-family: "MARTIN Regular";
  margin: 5vh 0;
}


.about__stacks-img {
  width: clamp(6rem, 20vw, 15rem);
  grid-area: 2 / 1 / span 1 / span 1;
  justify-self: end;
  align-self: auto;
}

.about__stacks-copy {
  font-size: var(--step-1-5);
  font-family: "inter", sans-serif;
  align-self: flex-start;
  max-width: 28ch;
  margin-bottom: 6rem;
  color: var(--dusty_black);
  line-height: 1.5;
}

.about__stacks__copy-title {
  font-weight: 600;
  margin-bottom: 1vh;
  line-height: normal;
}

.second {
  margin-top: 3rem;
}

.about__highlight {
  color: var(--dusty_black);
  text-decoration-color: var(--menu_black);
}

@media (max-width: 720px) {

  .about__stacks-img {
    width: clamp(6rem, 20vw, 12rem);
  }

  .about__stacks-copy {
    max-width: 21ch;
  }

  .second {
    margin-top: 1.5rem;
  }

}