footer {
  color: var(--footer_white);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Inter", sans-serif;
  width: 100%;
}

.footer__contact {
  font-size: var(--footer-contact);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}

.nm {
  margin: 0;
}

.small__title w {
  color: var(--off_white);
}

.footer__contact span {
  background: var(--dusty_pink);
  height: clamp(1px, 1vw, 1px);
  width: clamp(20px, 7vw, 700px);
  display: inline-block;
  align-self: center;
  justify-self: center;
}

.footer__name {
  color: var(--dusty_pink);
  font-size: clamp(30px, 20.9vw, 325px);
  text-transform: uppercase;
  line-height: var(--line-height-s);
  font-family: "MARTIN Regular";
  overflow: hidden;
}

.footer__name :nth-child(1) {
  margin: var(--mt);
}

.footer__made-info {
  margin: 5vh 0 7vh;
  font-size: var(--step-0);
}

.menu__link-footer {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.menu__link-footer::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: var(--dusty_pink);
  transition: width 0.2s ease-in;
}

.menu__link-footer:hover::after {
  width: 100%;
}

.dino_animation {
  position: absolute;
  right: 0;
  bottom: 0;
  height: clamp(70px, 11vw, 130px);
  opacity: 0;
}

.footer__svg {
  position: absolute;
  right: 80%;
  bottom: 87%;
  height: 80%;
}

.dino_animation img {
  height: 100%;
}

@media (min-width: 768px) {
  .footer__contact {
    max-width: 49%;
  }

  .footer__made-info {
    margin: 7vh 0;
  }
}
