.home__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

.hero__content {
  position: relative;
  flex: 1 1;
  width: 100%;
  display: flex;
}

.hero__image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 55%;
}


.hero__grid {
  position: relative;
  display: grid;
  grid-template-rows: 100vh auto 18fr 20fr 10fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 6fr 1fr 1fr 1fr;
  max-width: 100%;
  gap: 2rem 0;
  z-index: 1;
}

.hero__copy {
  grid-area: 1 / 3 / span 1 / span 3;
  color: var(--white);
  font-size: var(--step-3);
  align-self: flex-end;
  justify-self: flex-start;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-bottom: 3rem;
}



.hero__copy-title {
  grid-area: 2 / 5 / span 1 / span 1;
  align-self: center;
}

.h1__title span {
  opacity: 0;
}

.hero__arrow {
  fill: var(--white);
  z-index: 1;
  grid-area: 3 / 2 / span 1 / span 1;
  align-self: baseline;
  width: 3vw;
  justify-self: end;
  margin-right: 2vw;
  margin-top: 0.4rem;
}

.hero__copy-info {
  color: var(--white);
  grid-area: 3 / 3 / span 1 / span 3;
  font-size: var(--step-2-1);
  font-family: 'Inter', sans-serif;
  line-height: var(--line-height-l);
  max-width: 14ch;
}


.hero__copy__based-content {
  z-index: 1;
  grid-area: 3 / 5 / span 2 / span 2;
  display: grid;
  grid-template-rows: 0.8fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.hero__copy-line {
  grid-area: 1 / 2 / span 1 / span 3;
}

.hero__copy-line span {
  background: var(--dusty_pink);
  display: inline-block;
  width: 83%;
  height: clamp(3px, 1vw, 8px);
}

.hero__based {
  z-index: 1;
  grid-area: 2 / 2 / span 1 / span 2;
  color: var(--white);
  font-size: var(--step-2-1);
  font-family: 'Inter', sans-serif;
  white-space: nowrap;
  row-gap: 6rem;
}

.hero__based-content {
  display: inline-block;
}

.hero__based-content-overlay div {
  display: none;
}


.hero__based-content-overlay div svg {
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / 60%);
}

.hero__based-content-overlay:hover div {
  display: block;
  position: fixed;
  inset: 0;
  width: min(95%, 15rem);
  margin: auto;
  box-shadow: 0 0 0 500vmax rgb(0 0 0 / 60%);
  pointer-events: none;
}



.hero__based-content-overlay span {
  text-decoration: underline;
  text-decoration-color: var(--dusty_pink);
  display: inline-block;
  cursor: pointer;
}

.hero__based-content-overlay:nth-child(2) {
  margin-left: 5px;
}

.hero__based-content-overlay span:hover {
  color: var(--dusty_pink);
  transform: scale(1.1);
  transition-duration: 1s
}


.cls-1 {
  fill: #fff;
  stroke: #000;
  stroke-linecap: "round";
  stroke-linejoin: "round";
  stroke-miterlimit: 10;
  stroke-width: 3
}

.note {
  fill: var(--dusty_pink);
  stroke: var(--dusty_pink);
}



.hero__based-show {
  margin-top: 1vh;
  font-size: var(--step-0);
}

.hero__based-show a {
  text-decoration-color: var(--white);
}

@media (min-width: 768px) {
  .hero__grid {
    grid-template-rows: 100vh auto 18fr 20fr 30fr;
  }

  .hero__copy-info {
    max-width: none;
  }

  .hero__copy span {
    margin-left: 2.5vw;
  }

}